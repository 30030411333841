<template>
	<div class="questions inside-banner mobile-top">
        <section class="py-5 bg9 bs-shadow">
      <div class="container">
          <div class="container">
        <h1 class="text-white mb0 lh1">Frequently Asked Questions</h1>
        <p class="text-white lh1">Answers to common questions related to the Spacesaver Group and Spacenet.</p>
      </div>

          <!--
        <input
          class="top-search bs-large mt0"
          type="text"
          placeholder="Search All Posts..."
          v-model="search"
          @click="searchReset()"
        />
        -->
      </div>
    </section>

    		<section class="py-5 faq-tease">
			<div class="container">
                <div class="d-flex number-showing" style="margin-bottom: 20px;">
                <router-link
                  :to="{ path: $store.state.route.from.fullPath }"
                  class="mb0 lh1 mrauto list-btn dib d-flex center"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                  Back
                </router-link>
            </div>


				<div
					v-if="loading == true || !faqPosts.length"
					class="col-xs-12 col-sm-12 col-12 col-lg-12"
					style="
              min-height: 302px; display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 20px;"
				>
					<div class="flex-box-wrap">
						<div class="flex-internal">
							<atom-spinner
								:animation-duration="1000"
								:size="40"
								:color="'#333'"
							/>
							<p style="color:#333;">Loading......</p>
						</div>
					</div>
				</div>

				<div v-else class="accordion" id="faq-01" data-config-id="faq">
					<div
						v-for="(faq, index) in faqPosts"
						:key="faq.index"
						class="mb-2 border-bottom bgwhite bs-shadow"
					>
						<h5 id="faq-heading1-01 faq-heading">
							<button
								class="btn btn-link btn-block text-left d-flex collapsed"
								type="button"
								data-toggle="collapse"
								:data-target="'#faq-collapse1-' + index"
								aria-expanded="false"
								aria-controls="faq-collapse1-01"
								style="white-space:normal !important; word-wrap: break-word"
							>
								<span v-html="faq.title.rendered"></span>
								<i class="p-1 ml-auto my-auto fas fa-arrow-circle-down"></i>
							</button>
						</h5>
						<div
							class="collapse answer"
							:id="'faq-collapse1-' + index"
							aria-labelledby="faq-heading1-01"
							data-parent="#faq-01"
							style="padding: 0 10px"
						>
							<p id="answer" v-html="faq.content.rendered"></p>
						</div>
					</div>
				</div>
				
			</div>
		</section>

	</div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";

//old auth with admin priveleges
//headers: {Authorization:'Bearer spacesaver/ab3f641f910eb693804bc5b097a5bda6'}

const auth = {
	headers: {
		Authorization: "Bearer spacesaver/a59d39c4db9c288752c13e9c1196c9cf",
	},
};

export default {
	name: "FAQ",
	components: {
		AtomSpinner,
	},
	data() {
		return {
			pageSlug: "",
			msg: "",
			offsetVal: 0,
			search: "",
			searchValue: "",
            media: [],
            faqPosts: [],
			loading: true,

		};
	},
	methods: {
		firstLoad: function() {
			this.loading = true;
			this.faqSync();
        },
        faqSync: function() {
			this.loading = true;
			if (this.$store.state.faq.faqAllPosts.length) {
				this.faqPosts = this.$store.state.faq.faqAllPosts;
				this.loading = false;
			} else {
				return axios
					.get(
						`https://spacenetcontent.spacesaver.com/wp-json/wp/v2/spacenet_faqs`
					)
					.then((response) => {
						//console.log(response);
						this.faqPosts = response.data;
						this.loading = false;
						this.$store.commit("UPDATE_FAQS_ALL", this.faqPosts);
					});
			}
		},
	},
	computed: {
		activeNav() {
			return this.$store.state.activePage;
		},
	},
	created() {
		this.$store.commit("showNavigation");
	},

	mounted() {
		this.firstLoad();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.bgwhite {
	background: #fff;
}
.faq-heading {
	margin-bottom: 0 !important;
}
.collapse {
	padding: 0px 10px !important;
}
.collapse.show {
	padding: 0px 10px !important;
}
.faq-01 .btn {
	text-decoration: none !important;
}
.faq-01 .btn:hover {
	text-decoration: none !important;
}

.btn-link {
	color: #333;
}

.btn-link i {
	color: rgba(92, 109, 126, 0.9);
}

.btn-link:hover {
	color: #000;
	text-decoration: none !important;
	background-color: transparent;
	border-color: transparent;
}

.btn-link:focus {
	color: #000;
	text-decoration: none !important;
}

.accordion h5 {
	margin-bottom: 0 !important;
	font-weight: bold !important;
	font-size: 1.1em !important;
	line-height: 1.2;
}

.accordion div {
	padding: 10px;
}

</style>
